/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import React, { useState, useEffect, useRef, useCallback } from 'react';

import {
  PieChart,
  Pie,
  Cell,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from 'recharts';
import Toolbar from '../../components/Toolbar';
import Button from '../../components/Button';
import LoadingAnimation from '../../components/Loading';

import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import {
  Container,
  PortfolioArea,
  ChartArea,
  ChartBlock,
  ChartTitle,
} from './styles';

// Define Types for Portfolio Data
interface Stock {
  code: string;
  name: string;
  quantity: number;
  unit_price: number;
}

interface Fii {
  code: string;
  name: string;
  quantity: number;
  unit_price: number;
}

interface Tesouro {
  code: string;
  name: string;
  quantity: number;
  amount_invested: number;
  current_value: number;
  expiration_date: string;
}

interface Portfolio {
  stocks: Stock[];
  fiis: Fii[];
  tesouro: Tesouro[];
}

interface BarData {
  name: string;
  invested: string;
}

interface PieData {
  name: string;
  value: number;
}

const PortfolioDistribution: React.FC = () => {
  const { addToast } = useToast();

  const [stocks, setStocks] = useState<Stock[]>([]);
  const [fiis, setFiis] = useState<Fii[]>([]);
  const [tesouro, setTesouro] = useState<Tesouro[]>([]);

  const [loading, setLoading] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    api.get<Portfolio>('wallet/positions').then((response) => {
      const portfolio = response.data;

      setStocks(portfolio.stocks);
      setFiis(portfolio.fiis);
      setTesouro(portfolio.tesouro);
      setLoading(false);
    });
  }, []);

  // Calculate pie chart data
  const totalStocks = stocks.reduce(
    (sum, stock) => sum + (stock.unit_price / 100) * stock.quantity,
    0,
  );
  const totalFiis = fiis.reduce(
    (sum, fii) => sum + (fii.unit_price / 100) * fii.quantity,
    0,
  );
  const totalTesouro = tesouro.reduce(
    (sum, t) => sum + t.current_value / 100,
    0,
  );

  const pieData: PieData[] = [
    { name: 'Stocks', value: totalStocks },
    { name: 'FIIs', value: totalFiis },
    { name: 'Tesouro', value: totalTesouro },
  ];

  // Custom label function for percentages on the pie chart
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }: any): JSX.Element => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    // Data for labels (corresponds to your pieData)
    const pieDataLabels = ['Stocks', 'FIIs', 'Tesouro']; // Example: replace this with your dynamic data

    return (
      <text
        x={x}
        y={y}
        fill="black"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        <tspan x={x} dy="-1em">
          {pieDataLabels[index]}
        </tspan>
        <tspan x={x} dy="1.2em">{`${(percent * 100).toFixed(2)}%`}</tspan>
      </text>
    );
  };

  // Prepare bar chart data
  const stockBarData: BarData[] = stocks.map((stock) => ({
    name: stock.code,
    invested: ((stock.unit_price / 100) * stock.quantity).toFixed(2),
  }));

  const fiiBarData: BarData[] = fiis.map((fii) => ({
    name: fii.code,
    invested: ((fii.unit_price / 100) * fii.quantity).toFixed(2),
  }));

  const tesouroBarData: BarData[] = tesouro.map((td) => ({
    name: td.name,
    invested: (td.current_value / 100).toFixed(2),
  }));

  // SE QUISER MOSTRAR O GRÁFICO POR GRUPO
  // const tesouroGrouped: { [code: string]: number } = {};
  // tesouro.forEach((t) => {
  //   if (!tesouroGrouped[t.code]) {
  //     tesouroGrouped[t.code] = 0;
  //   }
  //   tesouroGrouped[t.code] += t.current_value / 100;
  // });
  // const tesouroBarData: BarData[] = Object.keys(tesouroGrouped).map((key) => ({
  //   name: `Code ${key}`,
  //   invested: tesouroGrouped[key].toFixed(2),
  // }));

  const [fileBase64, setFileBase64] = useState<string | null>(null);
  const [fileName, setFileName] = useState<string | null>(null);
  const [uploading, setUploading] = useState(false);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file); // Read file as base64
      reader.onload = () => {
        const base64String = reader.result as string;
        setFileBase64(base64String);
        setFileName(file.name);
      };
      reader.onerror = (error) => {
        console.error('Error reading file:', error);
      };
    }
  };

  const handleUpload = async () => {
    if (!fileBase64) {
      alert('Please select a file first!');
      return;
    }

    setUploading(true);
    try {
      const response = await api.post('wallet/ingestion', {
        file: fileBase64.split(',')[1],
      });

      // Handle success
      addToast({
        type: 'success',
        title: 'Sucesso',
        description: 'Dados importados com sucesso!',
      });
      setUploading(false);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro na importação dos dados',
        description: 'error',
      });
      setUploading(false);
    }
  };

  return loading ? (
    <Container>
      <Toolbar />
      <LoadingAnimation />
    </Container>
  ) : (
    <Container ref={containerRef}>
      <Toolbar />
      <div
        style={{ display: 'flex', flexDirection: 'column', margin: '120px 5%' }}
      >
        <label
          style={{
            backgroundColor: '#eea302',
            borderRadius: '5px',
            color: 'black',
            width: '230px',
            margin: '8px 0',
            paddingLeft: '50px',
          }}
          htmlFor="fileInput"
          className="custom-file-button"
        >
          Selecionar planilha
        </label>
        <input
          id="fileInput"
          style={{ display: 'none' }}
          type="file"
          accept=".xlsx, .xls"
          onChange={handleFileChange}
        />
        {/* eslint-disable-next-line */}
        {fileName && <p>Selected file: {fileName}</p>}

        <Button onClick={handleUpload} disabled={loading}>
          {uploading ? 'Enviando...' : 'Enviar planilha'}
        </Button>
        <PortfolioArea>
          <h2>Distribuição da carteira</h2>
          <ChartArea>
            <ChartBlock>
              <ChartTitle>Distribuição Total</ChartTitle>
              <PieChart
                margin={{ top: 10, right: 30, left: 60, bottom: 50 }}
                width={600}
                height={400}
              >
                <Pie
                  data={pieData}
                  cx={200}
                  cy={200}
                  outerRadius={150}
                  label={renderCustomizedLabel}
                  labelLine={false}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {pieData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={['#0088FE', '#00C49F', '#FFBB28'][index % 3]}
                    />
                  ))}
                </Pie>
                <Tooltip
                  contentStyle={{
                    backgroundColor: '#17171e',
                    borderRadius: '8px',
                  }}
                  itemStyle={{ color: '#fff' }}
                  formatter={(value: number) => value.toFixed(2)}
                />
              </PieChart>
            </ChartBlock>

            <ChartBlock>
              <ChartTitle>Stocks</ChartTitle>
              <BarChart
                width={600}
                height={400}
                data={stockBarData}
                margin={{ top: 10, right: 30, left: 0, bottom: 50 }}
              >
                <XAxis
                  interval={0} // Display all labels
                  angle={-45} // Rotate labels by -45 degrees
                  textAnchor="end" // Align labels correctly after rotation
                  dy={10} // Add padding between labels and axis
                  dataKey="name"
                />
                <YAxis />
                <Tooltip
                  contentStyle={{
                    backgroundColor: '#17171e',
                    borderRadius: '8px',
                  }} // Tooltip background color
                  itemStyle={{ color: '#fff' }}
                  cursor={false}
                />
                <Bar dataKey="invested" fill="#8884d8" />
              </BarChart>
            </ChartBlock>

            <ChartBlock>
              <ChartTitle>FIIs</ChartTitle>
              <BarChart
                width={600}
                height={400}
                data={fiiBarData}
                margin={{ top: 10, right: 30, left: 0, bottom: 50 }}
              >
                <XAxis
                  interval={0} // Display all labels
                  angle={-45} // Rotate labels by -45 degrees
                  textAnchor="end" // Align labels correctly after rotation
                  dy={10} // Add padding between labels and axis
                  dataKey="name"
                />
                <YAxis />
                <Tooltip
                  contentStyle={{
                    backgroundColor: '#17171e',
                    borderRadius: '8px',
                  }} // Tooltip background color
                  itemStyle={{ color: '#fff' }}
                  cursor={false}
                />
                <Bar dataKey="invested" fill="#82ca9d" />
              </BarChart>
            </ChartBlock>

            <ChartBlock>
              <ChartTitle>Tesouro</ChartTitle>
              <BarChart
                width={600}
                height={400}
                data={tesouroBarData}
                margin={{ top: 10, right: 30, left: 0, bottom: 50 }}
              >
                <XAxis
                  interval={0} // Display all labels
                  angle={-45} // Rotate labels by -45 degrees
                  textAnchor="end" // Align labels correctly after rotation
                  dy={10} // Add padding between labels and axis
                  dataKey="name"
                />
                <YAxis domain={['auto', 'auto']} />
                <Tooltip
                  contentStyle={{
                    backgroundColor: '#17171e',
                    borderRadius: '8px',
                  }} // Tooltip background color
                  itemStyle={{ color: '#fff' }}
                  cursor={false}
                />
                <Bar dataKey="invested" fill="#ffc658" />
              </BarChart>
            </ChartBlock>
          </ChartArea>
        </PortfolioArea>
      </div>
    </Container>
  );
};

export default PortfolioDistribution;
