/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Form as UnformForm } from '@unform/web';
import { FormHandles } from '@unform/core';

import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import { FiDollarSign } from 'react-icons/fi';
import Toolbar from '../../components/Toolbar';
import Button from '../../components/Button';
import LoadingAnimation from '../../components/Loading';
import Input from '../../components/Input';

import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import {
  Container,
  PortfolioArea,
  ChartArea,
  ChartBlock,
  ChartTitle,
  DataCardsContainer,
  CardContainer,
  DataCard,
  LabelsContainer,
  LabelText,
  ValuesContainer,
  ValueText,
} from './styles';
import { Allocation, BuyAsset, BuyTD } from './interfaces';

interface Stock {
  code: string;
  name: string;
  quantity: number;
  unit_price: number;
}

interface Fii {
  code: string;
  name: string;
  quantity: number;
  unit_price: number;
}

interface Tesouro {
  code: string;
  name: string;
  quantity: number;
  amount_invested: number;
  current_value: number;
  expiration_date: string;
}

interface Portfolio {
  stocks: Stock[];
  fiis: Fii[];
  tesouro: Tesouro[];
}

interface PieData {
  name: string;
  value: number;
}

interface AllocationFormData {
  amountToInvest: number;
}

const PortfolioAllocation: React.FC = () => {
  const { addToast } = useToast();

  const [stocks, setStocks] = useState<Stock[]>([]);
  const [fiis, setFiis] = useState<Fii[]>([]);
  const [tesouro, setTesouro] = useState<Tesouro[]>([]);

  const [stocksToBuy, setStocksToBuy] = useState<BuyAsset[]>([]);
  const [fiisToBuy, setFiisToBuy] = useState<BuyAsset[]>([]);
  const [tdToBuy, setTDToBuy] = useState<BuyTD[]>([]);

  const [stocksToSell, setStocksToSell] = useState<string[]>([]);
  const [fiisToSell, setFiisToSell] = useState<string[]>([]);

  const [reallocPieData, setReallocPieData] = useState<PieData[]>([]);

  const [loading, setLoading] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const allocateAssetsFormRef = useRef<FormHandles>(null);

  useEffect(() => {
    api.get<Portfolio>('wallet/positions').then((response) => {
      const portfolio = response.data;

      setStocks(portfolio.stocks);
      setFiis(portfolio.fiis);
      setTesouro(portfolio.tesouro);
      setLoading(false);
    });
  }, []);

  // Calculate pie chart data
  const totalStocks = stocks.reduce(
    (sum, stock) => sum + (stock.unit_price / 100) * stock.quantity,
    0,
  );
  const totalFiis = fiis.reduce(
    (sum, fii) => sum + (fii.unit_price / 100) * fii.quantity,
    0,
  );
  const totalTesouro = tesouro.reduce(
    (sum, t) => sum + t.current_value / 100,
    0,
  );

  const pieData: PieData[] = [
    { name: 'Stocks', value: totalStocks },
    { name: 'FIIs', value: totalFiis },
    { name: 'Tesouro', value: totalTesouro },
  ];

  // Custom label function for percentages on the pie chart
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }: any): JSX.Element => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    // Data for labels (corresponds to your pieData)
    const pieDataLabels = ['Stocks', 'FIIs', 'Tesouro']; // Example: replace this with your dynamic data

    return (
      <text
        x={x}
        y={y}
        fill="black"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        <tspan x={x} dy="-1em">
          {pieDataLabels[index]}
        </tspan>
        <tspan x={x} dy="1.2em">{`${(percent * 100).toFixed(2)}%`}</tspan>
      </text>
    );
  };

  const [allocating, setAllocating] = useState(false);

  const handleAllocation = async (data: AllocationFormData) => {
    setAllocating(true);
    try {
      const response = await api.post<Allocation>('wallet/allocation', {
        amount_to_invest: Number((data.amountToInvest * 100).toFixed(0)),
      });

      if (response.data.sell.stocks.length > 0) {
        setStocksToSell(response.data.sell.stocks);
      }

      if (response.data.sell.fiis.length > 0) {
        setFiisToSell(response.data.sell.fiis);
      }

      if (response.data.buy.stocks.length > 0) {
        setStocksToBuy(response.data.buy.stocks);
      }

      if (response.data.buy.fiis.length > 0) {
        setFiisToBuy(response.data.buy.fiis);
      }

      if (response.data.buy.tesouro.length > 0) {
        setTDToBuy(response.data.buy.tesouro);
      }

      const currentStocks = stocks;
      const filteredStocks = currentStocks.filter((stock) => {
        return !response.data.sell.stocks.find(
          (sellStock) => sellStock === stock.code,
        );
      });

      const currentFiis = fiis;
      const filteredFiis = currentFiis.filter((fii) => {
        return !response.data.sell.fiis.find((sellFii) => sellFii === fii.code);
      });

      let stocksAmountToBuy = 0;
      if (response.data.buy.stocks.length > 0) {
        stocksAmountToBuy = response.data.buy.stocks.reduce(
          (sum, stock) => sum + stock.pending_amount / 100,
          0,
        );
      }

      let fiisAmountToBuy = 0;
      if (response.data.buy.fiis.length > 0) {
        fiisAmountToBuy = response.data.buy.fiis.reduce(
          (sum, fii) => sum + fii.pending_amount / 100,
          0,
        );
      }

      let tdAmountToBuy = 0;
      if (response.data.buy.tesouro.length > 0) {
        tdAmountToBuy = response.data.buy.tesouro.reduce(
          (sum, td) => sum + td.pending_amount / 100,
          0,
        );
      }

      const totalReallocStocks =
        filteredStocks.reduce(
          (sum, stock) => sum + (stock.unit_price / 100) * stock.quantity,
          0,
        ) + stocksAmountToBuy;
      const totalReallocFiis =
        filteredFiis.reduce(
          (sum, fii) => sum + (fii.unit_price / 100) * fii.quantity,
          0,
        ) + fiisAmountToBuy;
      const totalReallocTD = totalTesouro + tdAmountToBuy;

      const newPieData: PieData[] = [
        { name: 'Stocks', value: totalReallocStocks },
        { name: 'FIIs', value: totalReallocFiis },
        { name: 'Tesouro', value: totalReallocTD },
      ];

      setReallocPieData(newPieData);
      setAllocating(false);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro na importação dos dados',
        description: 'error',
      });
      setAllocating(false);
    }
  };

  return loading ? (
    <Container>
      <Toolbar />
      <LoadingAnimation />
    </Container>
  ) : (
    <Container ref={containerRef}>
      <Toolbar />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          margin: '120px 5%',
          width: '90%',
          alignItems: 'center',
        }}
      >
        <UnformForm
          ref={allocateAssetsFormRef}
          onSubmit={handleAllocation}
          style={{ display: 'flex', flexDirection: 'row', width: '30%' }}
        >
          <Input
            name="amountToInvest"
            icon={FiDollarSign}
            placeholder="Quantia disponível para investir"
          />
          <Button
            disabled={loading}
            type="submit"
            style={{ marginLeft: '8px' }}
          >
            {allocating ? 'Alocando...' : 'Alocar recursos'}
          </Button>
        </UnformForm>
        <PortfolioArea>
          <h2>Distribuição da carteira</h2>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <ChartArea>
              <ChartBlock>
                <ChartTitle>Distribuição Atual de ativos</ChartTitle>
                <PieChart
                  margin={{ top: 10, right: 30, left: 60, bottom: 50 }}
                  width={600}
                  height={400}
                >
                  <Pie
                    data={pieData}
                    cx={200}
                    cy={200}
                    outerRadius={150}
                    label={renderCustomizedLabel}
                    labelLine={false}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {pieData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={['#0088FE', '#00C49F', '#FFBB28'][index % 3]}
                      />
                    ))}
                  </Pie>
                  <Tooltip
                    contentStyle={{
                      backgroundColor: '#17171e',
                      borderRadius: '8px',
                    }}
                    itemStyle={{ color: '#fff' }}
                    formatter={(value: number) => value.toFixed(2)}
                  />
                </PieChart>
              </ChartBlock>
            </ChartArea>
            <ChartArea>
              <ChartBlock>
                <ChartTitle>Distribuição Almejada de ativos</ChartTitle>
                <PieChart
                  margin={{ top: 10, right: 30, left: 60, bottom: 50 }}
                  width={600}
                  height={400}
                >
                  <Pie
                    data={reallocPieData}
                    cx={200}
                    cy={200}
                    outerRadius={150}
                    label={renderCustomizedLabel}
                    labelLine={false}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {pieData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={['#0088FE', '#00C49F', '#FFBB28'][index % 3]}
                      />
                    ))}
                  </Pie>
                  <Tooltip
                    contentStyle={{
                      backgroundColor: '#17171e',
                      borderRadius: '8px',
                    }}
                    itemStyle={{ color: '#fff' }}
                    formatter={(value: number) => value.toFixed(2)}
                  />
                </PieChart>
              </ChartBlock>
            </ChartArea>
          </div>
          {stocksToBuy.length > 0 && (
            <>
              <h2>Ações a comprar</h2>
              <DataCardsContainer>
                {stocksToBuy.map((stock) => {
                  return (
                    <CardContainer>
                      <DataCard>
                        <LabelsContainer>
                          <LabelText>Código:</LabelText>
                          <LabelText>Nome:</LabelText>
                          <LabelText>Quantia:</LabelText>
                        </LabelsContainer>
                        <ValuesContainer>
                          <ValueText>{stock.code}</ValueText>
                          <ValueText>{stock.name}</ValueText>
                          {/* eslint-disable-next-line */}
                          <ValueText>{(stock.pending_amount / 100).toFixed(2)}</ValueText>
                        </ValuesContainer>
                      </DataCard>
                    </CardContainer>
                  );
                })}
              </DataCardsContainer>
            </>
          )}
          {stocksToSell.length > 0 && (
            <>
              <h2>Ações a vender</h2>
              <DataCardsContainer>
                {stocksToSell.map((stock) => {
                  return (
                    <CardContainer>
                      <DataCard>
                        <LabelsContainer>
                          <LabelText>Código:</LabelText>
                        </LabelsContainer>
                        <ValuesContainer>
                          <ValueText>{stock}</ValueText>
                        </ValuesContainer>
                      </DataCard>
                    </CardContainer>
                  );
                })}
              </DataCardsContainer>
            </>
          )}
          {fiisToBuy.length > 0 && (
            <>
              <h2>Fiis a comprar</h2>
              <DataCardsContainer>
                {fiisToBuy.map((fii) => {
                  return (
                    <CardContainer>
                      <DataCard>
                        <LabelsContainer>
                          <LabelText>Código:</LabelText>
                          <LabelText>Nome:</LabelText>
                          <LabelText>Quantia:</LabelText>
                        </LabelsContainer>
                        <ValuesContainer>
                          <ValueText>{fii.code}</ValueText>
                          <ValueText>{fii.name}</ValueText>
                          {/* eslint-disable-next-line */}
                          <ValueText>{(fii.pending_amount / 100).toFixed(2)}</ValueText>
                        </ValuesContainer>
                      </DataCard>
                    </CardContainer>
                  );
                })}
              </DataCardsContainer>
            </>
          )}
          {fiisToSell.length > 0 && (
            <>
              <h2>Fiis a vender</h2>
              <DataCardsContainer>
                {fiisToSell.map((stock) => {
                  return (
                    <CardContainer>
                      <DataCard>
                        <LabelsContainer>
                          <LabelText>Código:</LabelText>
                        </LabelsContainer>
                        <ValuesContainer>
                          <ValueText>{stock}</ValueText>
                        </ValuesContainer>
                      </DataCard>
                    </CardContainer>
                  );
                })}
              </DataCardsContainer>
            </>
          )}
          {tdToBuy.length > 0 && (
            <>
              <h2>Tesouro Direto a comprar</h2>
              <DataCardsContainer>
                {tdToBuy.map((td) => {
                  return (
                    <CardContainer>
                      <DataCard>
                        <LabelsContainer>
                          <LabelText>Código:</LabelText>
                          <LabelText>Nome:</LabelText>
                          <LabelText>Quantia:</LabelText>
                        </LabelsContainer>
                        <ValuesContainer>
                          <ValueText>{td.group_id}</ValueText>
                          <ValueText>{td.name.split(',').slice(-1)}</ValueText>
                          {/* eslint-disable-next-line */}
                          <ValueText>{(td.pending_amount / 100).toFixed(2)}</ValueText>
                        </ValuesContainer>
                      </DataCard>
                    </CardContainer>
                  );
                })}
              </DataCardsContainer>
            </>
          )}
        </PortfolioArea>
      </div>
    </Container>
  );
};

export default PortfolioAllocation;
