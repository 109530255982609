/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Modal } from 'react-responsive-modal';
import * as Yup from 'yup';

import { Form as UnformForm } from '@unform/web';
import { FormHandles } from '@unform/core';

import {
  FiEdit,
  FiSearch,
  FiTrash2,
  FiPlusCircle,
  FiList,
  FiPercent,
  FiPlus,
} from 'react-icons/fi';
import {
  Container,
  PortfolioArea,
  Subtitle,
  DataCardsContainer,
  DataCard,
  LabelsContainer,
  ValuesContainer,
  ValueText,
  LabelText,
  CardContainer,
  ButtonsContainer,
  ButtonContainer,
  AddCardButton,
  TesouroInputField,
  TesouroInputFieldButton,
  ViewAssetFormData,
  PortfolioFooterContainer,
} from './styles';

import Toolbar from '../../components/Toolbar';
import LoadingAnimation from '../../components/Loading';

import api from '../../services/api';
import Input from '../../components/Input';
import Button from '../../components/Button';
import getValidationErrors from '../../utils/getValidationErros';
import { useToast } from '../../hooks/toast';

interface AssetDistribution {
  type: 'STOCK' | 'FII' | 'TD';
  name: string;
  code: string;
  weight: number;
}

type AssetDistributionGroups = {
  STOCK: AssetDistribution[];
  FII: AssetDistribution[];
  TD: AssetDistribution[];
};

const Portfolio: React.FC = () => {
  const { addToast } = useToast();

  const [stocks, setStocks] = useState<AssetDistribution[]>([]);
  const [fiis, setFiis] = useState<AssetDistribution[]>([]);
  const [tesouro, setTesouro] = useState<AssetDistribution[]>([]);

  // Modals
  const [isAddStockModalOpen, setIsAddStockModalOpen] = useState(false);
  const [isAddFiiModalOpen, setIsAddFiiModalOpen] = useState(false);
  const [isAddTesouroModalOpen, setIsAddTesouroModalOpen] = useState(false);

  const [isViewStockModalOpen, setIsViewStockModalOpen] = useState(false);
  const [isViewFiiModalOpen, setIsViewFiiModalOpen] = useState(false);
  const [isViewTesouroModalOpen, setIsViewTesouroModalOpen] = useState(false);

  const [viewAssetIndex, setViewAssetIndex] = useState(0);

  const [isDeleteStockModalOpen, setIsDeleteStockModalOpen] = useState(false);
  const [isDeleteFiiModalOpen, setIsDeleteFiiModalOpen] = useState(false);
  const [isDeleteTesouroModalOpen, setIsDeleteTesouroModalOpen] = useState(
    false,
  );

  const [isEditStockModalOpen, setIsEditStockModalOpen] = useState(false);
  const [isEditFiiModalOpen, setIsEditFiiModalOpen] = useState(false);
  const [isEditTesouroModalOpen, setIsEditTesouroModalOpen] = useState(false);

  const [tesouroInputs, setTesouroInputs] = useState(['']);

  const [loading, setLoading] = useState(true);

  const containerRef = useRef<HTMLDivElement>(null);
  const addStockFormRef = useRef<FormHandles>(null);
  const addFiiFormRef = useRef<FormHandles>(null);
  const addTesouroFormRef = useRef<FormHandles>(null);

  useEffect(() => {
    api.get<AssetDistribution[]>('wallet/distribution').then((response) => {
      const { STOCK, FII, TD } = response.data.reduce<AssetDistributionGroups>(
        (acc, item) => {
          acc[item.type].push(item);
          return acc;
        },
        { STOCK: [], FII: [], TD: [] },
      );
      setStocks(STOCK);
      setFiis(FII);
      setTesouro(TD);
      setLoading(false);
    });
  }, []);

  const handleToggleModal = useCallback(
    (modalType: string) => {
      switch (modalType) {
        case 'addStock':
          setIsAddStockModalOpen(!isAddStockModalOpen);
          break;
        case 'viewStock':
          setIsViewStockModalOpen(!isViewStockModalOpen);
          break;
        case 'deleteStock':
          setIsDeleteStockModalOpen(!isDeleteStockModalOpen);
          break;
        case 'editStock':
          setIsEditStockModalOpen(!isEditStockModalOpen);
          break;
        case 'addFii':
          setIsAddFiiModalOpen(!isAddFiiModalOpen);
          break;
        case 'viewFii':
          setIsViewFiiModalOpen(!isViewFiiModalOpen);
          break;
        case 'deleteFii':
          setIsDeleteFiiModalOpen(!isDeleteFiiModalOpen);
          break;
        case 'editFii':
          setIsEditFiiModalOpen(!isEditFiiModalOpen);
          break;
        case 'addTesouro':
          setIsAddTesouroModalOpen(!isAddTesouroModalOpen);
          break;
        case 'viewTesouro':
          setIsViewTesouroModalOpen(!isViewTesouroModalOpen);
          break;
        case 'deleteTesouro':
          setIsDeleteTesouroModalOpen(!isDeleteTesouroModalOpen);
          break;
        case 'editTesouro': {
          const newTesouroInputs = tesouro[viewAssetIndex].name.split(',');
          setTesouroInputs([...newTesouroInputs]);
          setIsEditTesouroModalOpen(!isEditTesouroModalOpen);
          break;
        }
        default:
          console.log('invalid modal');
          break;
      }
    },
    [
      isAddStockModalOpen,
      isAddFiiModalOpen,
      isAddTesouroModalOpen,
      isViewStockModalOpen,
      isViewFiiModalOpen,
      isViewTesouroModalOpen,
      isDeleteStockModalOpen,
      isDeleteFiiModalOpen,
      isDeleteTesouroModalOpen,
      isEditStockModalOpen,
      isEditFiiModalOpen,
      isEditTesouroModalOpen,
      tesouro,
      viewAssetIndex,
    ],
  );

  const handleAddAsset = useCallback(
    async (assetData: AssetDistribution) => {
      addStockFormRef.current?.setErrors({});
      addFiiFormRef.current?.setErrors({});
      addTesouroFormRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          code: Yup.string().required('Código é obrigatório'),
          name: Yup.string().required('Nome é obrigatório'),
          weight: Yup.number()
            .typeError('Valor deve ser um número')
            .required('Informe o peso do ativo na carteira'),
        });
        await schema.validate(assetData, { abortEarly: false });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          addStockFormRef.current?.setErrors(errors);
          addFiiFormRef.current?.setErrors(errors);
          addTesouroFormRef.current?.setErrors(errors);

          return;
        }
      }

      const asset = {
        type: assetData.type,
        name: assetData.name,
        code: assetData.code,
        weight: assetData.weight * 100,
      } as AssetDistribution;

      switch (assetData.type) {
        case 'STOCK': {
          const hasStock = stocks.find(
            (stock) => stock.code === assetData.code,
          );
          if (hasStock) {
            addStockFormRef.current?.setErrors({
              code: 'stock already in wallet',
            });
            return;
          }

          setStocks([...stocks, asset]);
          setIsAddStockModalOpen(false);
          break;
        }
        case 'FII': {
          const hasFii = fiis.find((fii) => fii.code === assetData.code);
          if (hasFii) {
            addFiiFormRef.current?.setErrors({
              code: 'fii already in wallet',
            });
            return;
          }

          setFiis([...fiis, asset]);
          setIsAddFiiModalOpen(false);
          break;
        }
        case 'TD': {
          const hasTesouro = tesouro.find(
            (tesouroAsset) => tesouroAsset.code === assetData.code,
          );
          if (hasTesouro) {
            addTesouroFormRef.current?.setErrors({
              code: 'wallet already has group with this name',
            });
            return;
          }

          const existingAssets = tesouro.flatMap((item) =>
            item.name.split(',').map((value) => value.trim()),
          );

          const hasAsset = tesouroInputs.some((value) =>
            existingAssets.includes(value),
          );

          if (hasAsset) {
            addTesouroFormRef.current?.setErrors({
              name:
                'wallet already has one or more of these assets is another group',
            });
            return;
          }

          const assetName = tesouroInputs.join(',');
          asset.name = assetName;

          setTesouroInputs(['']);
          setTesouro([...tesouro, asset]);
          setIsAddTesouroModalOpen(false);
          break;
        }
        default:
          console.log('unknow asset type');
      }
    },
    [stocks, fiis, tesouro, tesouroInputs],
  );

  const handleSaveAsset = useCallback(
    async (assetData: AssetDistribution) => {
      addStockFormRef.current?.setErrors({});
      addFiiFormRef.current?.setErrors({});
      addTesouroFormRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          code: Yup.string().required('Código é obrigatório'),
          name: Yup.string().required('Nome é obrigatório'),
          weight: Yup.number()
            .typeError('Valor deve ser um número')
            .required('Informe o peso do ativo na carteira'),
        });
        await schema.validate(assetData, { abortEarly: false });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          addStockFormRef.current?.setErrors(errors);
          addFiiFormRef.current?.setErrors(errors);
          addTesouroFormRef.current?.setErrors(errors);

          return;
        }
      }

      switch (assetData.type) {
        case 'STOCK': {
          const newStocks = stocks;
          newStocks[viewAssetIndex].name = assetData.name;
          newStocks[viewAssetIndex].code = assetData.code;
          newStocks[viewAssetIndex].weight = assetData.weight * 100;

          setStocks(newStocks);
          setIsEditStockModalOpen(false);
          break;
        }
        case 'FII': {
          const newFiis = fiis;
          newFiis[viewAssetIndex].name = assetData.name;
          newFiis[viewAssetIndex].code = assetData.code;
          newFiis[viewAssetIndex].weight = assetData.weight * 100;

          setFiis(newFiis);
          setIsEditFiiModalOpen(false);
          break;
        }
        case 'TD': {
          const newTesouro = tesouro;
          newTesouro[viewAssetIndex].code = assetData.code;
          newTesouro[viewAssetIndex].weight = assetData.weight * 100;

          newTesouro[viewAssetIndex].name = tesouroInputs.join(',');

          setTesouroInputs(['']);
          setTesouro(newTesouro);
          setIsEditTesouroModalOpen(false);
          break;
        }
        default:
          console.log('unknow asset type');
      }
    },
    [stocks, fiis, viewAssetIndex, tesouro, tesouroInputs],
  );

  const handleAddTesouroAsset = useCallback(() => {
    setTesouroInputs([...tesouroInputs, '']);
  }, [tesouroInputs]);

  const handleRemoveTesouroAsset = useCallback(
    (index) => {
      const newAssets = [...tesouroInputs];
      newAssets.splice(index, 1);
      setTesouroInputs(newAssets);
    },
    [tesouroInputs],
  );

  const handleInputChange = useCallback(
    (index, event) => {
      const newAssets = [...tesouroInputs];
      newAssets[index] = event.target.value;
      setTesouroInputs(newAssets);
    },
    [tesouroInputs],
  );

  const removeAssetByIndex = useCallback(
    (assetType, index) => {
      switch (assetType) {
        case 'stock': {
          const newStocks = stocks;
          newStocks.splice(index, 1);
          setStocks(newStocks);
          break;
        }
        case 'fii': {
          const newFiis = fiis;
          newFiis.splice(index, 1);
          setFiis(newFiis);
          break;
        }
        case 'tesouro': {
          const newTesouro = tesouro;
          newTesouro.splice(index, 1);
          setTesouro(newTesouro);
          break;
        }
        default:
          console.log('must select asset type');
      }
    },
    [stocks, fiis, tesouro],
  );

  const handleSubmitWallet = useCallback(async () => {
    const payload = {
      stocks,
      fiis,
      tesouro,
    };

    try {
      await api.post('wallet/setup', payload);

      addToast({
        type: 'success',
        title: 'Registro criado',
        description: 'Conta cadastrada com sucesso!',
      });
    } catch (err) {
      const errorMessage = err.response.data.message;

      addToast({
        type: 'error',
        title: 'Erro no cadastro de carteira',
        description: errorMessage,
      });
    }
  }, [stocks, fiis, tesouro, addToast]);

  return loading ? (
    <Container>
      <Toolbar />
      <LoadingAnimation />
    </Container>
  ) : (
    <Container ref={containerRef}>
      <Toolbar />
      {isAddStockModalOpen && (
        <Modal
          open={isAddStockModalOpen}
          showCloseIcon={false}
          onClose={() => handleToggleModal('addStock')}
          center
          classNames={{
            modal: 'addAssetModal',
          }}
          container={containerRef.current as Element}
        >
          <UnformForm ref={addStockFormRef} onSubmit={handleAddAsset}>
            <h2>Adicionar ação</h2>
            <Input name="type" hidden value="STOCK" />
            <Input name="code" icon={FiList} placeholder="Código do ativo" />
            <Input name="name" icon={FiList} placeholder="Nome do ativo" />
            <Input name="weight" icon={FiPercent} placeholder="Peso do ativo" />
            <div className="submitSavingsAccount">
              <Button
                type="button"
                onClick={() => handleToggleModal('addStock')}
              >
                Cancelar
              </Button>
              <Button type="submit">Adicionar</Button>
            </div>
          </UnformForm>
        </Modal>
      )}
      {isEditStockModalOpen && (
        <Modal
          open={isEditStockModalOpen}
          showCloseIcon={false}
          onClose={() => handleToggleModal('editStock')}
          center
          classNames={{
            modal: 'addAssetModal',
          }}
          container={containerRef.current as Element}
        >
          <UnformForm ref={addStockFormRef} onSubmit={handleSaveAsset}>
            <h2>Editar ação</h2>
            <Input name="type" hidden value="STOCK" />
            <Input
              name="code"
              icon={FiList}
              placeholder="Código do ativo"
              defaultValue={stocks[viewAssetIndex].code}
            />
            <Input
              name="name"
              icon={FiList}
              placeholder="Nome do ativo"
              defaultValue={stocks[viewAssetIndex].name}
            />
            <Input
              name="weight"
              icon={FiPercent}
              placeholder="Peso do ativo"
              defaultValue={(stocks[viewAssetIndex].weight / 100).toFixed(2)}
            />
            <div className="submitSavingsAccount">
              <Button
                type="button"
                onClick={() => handleToggleModal('editStock')}
              >
                Cancelar
              </Button>
              <Button type="submit">Salvar</Button>
            </div>
          </UnformForm>
        </Modal>
      )}
      {isViewStockModalOpen && (
        <Modal
          open={isViewStockModalOpen}
          showCloseIcon={false}
          onClose={() => handleToggleModal('viewStock')}
          center
          classNames={{
            modal: 'addAssetModal',
          }}
          container={containerRef.current as Element}
        >
          <ViewAssetFormData>
            <h2>Detalhes do ativo</h2>
            <div className="viewRow">
              <div className="viewLabel">Código do ativo:</div>
              <div className="viewData">{stocks[viewAssetIndex].code}</div>
            </div>
            <div className="viewRow">
              <div className="viewLabel">Nome do ativo:</div>
              <div className="viewData">{stocks[viewAssetIndex].name}</div>
            </div>
            <div className="viewRow">
              <div className="viewLabel">Peso do ativo:</div>
              <div className="viewData">
                {/* eslint-disable-next-line */}
                {(stocks[viewAssetIndex].weight / 100).toFixed(2)}
              </div>
            </div>
            <div className="closeButton">
              <Button
                type="button"
                onClick={() => handleToggleModal('viewStock')}
              >
                Fechar
              </Button>
            </div>
          </ViewAssetFormData>
        </Modal>
      )}
      {isDeleteStockModalOpen && (
        <Modal
          open={isDeleteStockModalOpen}
          showCloseIcon={false}
          onClose={() => handleToggleModal('deleteStock')}
          center
          classNames={{
            modal: 'addAssetModal',
          }}
          container={containerRef.current as Element}
        >
          <ViewAssetFormData>
            <h2>Detalhes do ativo</h2>
            <div className="viewRow">
              <div className="viewLabel">Código do ativo:</div>
              <div className="viewData">{stocks[viewAssetIndex].code}</div>
            </div>
            <div className="viewRow">
              <div className="viewLabel">Nome do ativo:</div>
              <div className="viewData">{stocks[viewAssetIndex].name}</div>
            </div>
            <div className="viewRow">
              <div className="viewLabel">Peso do ativo:</div>
              <div className="viewData">
                {/* eslint-disable-next-line */}
                {(stocks[viewAssetIndex].weight / 100).toFixed(2)}
              </div>
            </div>
            <div className="submitSavingsAccount">
              <Button
                type="button"
                onClick={() => handleToggleModal('deleteStock')}
              >
                Fechar
              </Button>
              <Button
                type="button"
                onClick={() => {
                  handleToggleModal('deleteStock');
                  removeAssetByIndex('stock', viewAssetIndex);
                }}
              >
                Deletar
              </Button>
            </div>
          </ViewAssetFormData>
        </Modal>
      )}
      {isAddFiiModalOpen && (
        <Modal
          open={isAddFiiModalOpen}
          showCloseIcon={false}
          onClose={() => handleToggleModal('addFii')}
          center
          classNames={{
            modal: 'addAssetModal',
          }}
          container={containerRef.current as Element}
        >
          <UnformForm ref={addFiiFormRef} onSubmit={handleAddAsset}>
            <h2>Adicionar FII</h2>
            <Input name="type" hidden value="FII" />
            <Input name="code" icon={FiList} placeholder="Código do ativo" />
            <Input name="name" icon={FiList} placeholder="Nome do ativo" />
            <Input name="weight" icon={FiPercent} placeholder="Peso do ativo" />
            <div className="submitSavingsAccount">
              <Button type="button" onClick={() => handleToggleModal('addFii')}>
                Cancelar
              </Button>
              <Button type="submit">Adicionar</Button>
            </div>
          </UnformForm>
        </Modal>
      )}
      {isViewFiiModalOpen && (
        <Modal
          open={isViewFiiModalOpen}
          showCloseIcon={false}
          onClose={() => handleToggleModal('viewFii')}
          center
          classNames={{
            modal: 'addAssetModal',
          }}
          container={containerRef.current as Element}
        >
          <ViewAssetFormData>
            <h2>Detalhes do ativo</h2>
            <div className="viewRow">
              <div className="viewLabel">Código do ativo:</div>
              <div className="viewData">{fiis[viewAssetIndex].code}</div>
            </div>
            <div className="viewRow">
              <div className="viewLabel">Nome do ativo:</div>
              <div className="viewData">{fiis[viewAssetIndex].name}</div>
            </div>
            <div className="viewRow">
              <div className="viewLabel">Peso do ativo:</div>
              <div className="viewData">
                {/* eslint-disable-next-line */}
                  {(fiis[viewAssetIndex].weight / 100).toFixed(2)}
              </div>
            </div>
            <div className="closeButton">
              <Button
                type="button"
                onClick={() => handleToggleModal('viewFii')}
              >
                Fechar
              </Button>
            </div>
          </ViewAssetFormData>
        </Modal>
      )}
      {isEditFiiModalOpen && (
        <Modal
          open={isEditFiiModalOpen}
          showCloseIcon={false}
          onClose={() => handleToggleModal('editFii')}
          center
          classNames={{
            modal: 'addAssetModal',
          }}
          container={containerRef.current as Element}
        >
          <UnformForm ref={addFiiFormRef} onSubmit={handleSaveAsset}>
            <h2>Editar Fii</h2>
            <Input name="type" hidden value="FII" />
            <Input
              name="code"
              icon={FiList}
              placeholder="Código do ativo"
              defaultValue={fiis[viewAssetIndex].code}
            />
            <Input
              name="name"
              icon={FiList}
              placeholder="Nome do ativo"
              defaultValue={fiis[viewAssetIndex].name}
            />
            <Input
              name="weight"
              icon={FiPercent}
              placeholder="Peso do ativo"
              defaultValue={(fiis[viewAssetIndex].weight / 100).toFixed(2)}
            />
            <div className="submitSavingsAccount">
              <Button
                type="button"
                onClick={() => handleToggleModal('editFii')}
              >
                Cancelar
              </Button>
              <Button type="submit">Salvar</Button>
            </div>
          </UnformForm>
        </Modal>
      )}
      {isDeleteFiiModalOpen && (
        <Modal
          open={isDeleteFiiModalOpen}
          showCloseIcon={false}
          onClose={() => handleToggleModal('deleteFii')}
          center
          classNames={{
            modal: 'addAssetModal',
          }}
          container={containerRef.current as Element}
        >
          <ViewAssetFormData>
            <h2>Detalhes do ativo</h2>
            <div className="viewRow">
              <div className="viewLabel">Código do ativo:</div>
              <div className="viewData">{fiis[viewAssetIndex].code}</div>
            </div>
            <div className="viewRow">
              <div className="viewLabel">Nome do ativo:</div>
              <div className="viewData">{fiis[viewAssetIndex].name}</div>
            </div>
            <div className="viewRow">
              <div className="viewLabel">Peso do ativo:</div>
              <div className="viewData">
                {/* eslint-disable-next-line */}
                  {(fiis[viewAssetIndex].weight / 100).toFixed(2)}
              </div>
            </div>
            <div className="submitSavingsAccount">
              <Button
                type="button"
                onClick={() => handleToggleModal('deleteFii')}
              >
                Fechar
              </Button>
              <Button
                type="button"
                onClick={() => {
                  handleToggleModal('deleteFii');
                  removeAssetByIndex('fii', viewAssetIndex);
                }}
              >
                Deletar
              </Button>
            </div>
          </ViewAssetFormData>
        </Modal>
      )}
      {isAddTesouroModalOpen && (
        <Modal
          open={isAddTesouroModalOpen}
          showCloseIcon={false}
          onClose={() => handleToggleModal('addTesouro')}
          center
          classNames={{
            modal: 'addAssetModal',
          }}
          container={containerRef.current as Element}
        >
          <UnformForm ref={addTesouroFormRef} onSubmit={handleAddAsset}>
            <h2>Adicionar Grupo de Tesouro Direto</h2>
            <Input name="type" hidden value="TD" />
            <Input name="code" icon={FiList} placeholder="Nome do grupo" />
            {tesouroInputs.map((input, index) => {
              return (
                <TesouroInputField>
                  <Input
                    name="name"
                    icon={FiList}
                    placeholder="Nome do título"
                    onChange={(e) => handleInputChange(index, e)}
                  />
                  <TesouroInputFieldButton
                    type="button"
                    onClick={() => handleRemoveTesouroAsset(index)}
                  >
                    <FiTrash2 title="Deletar ativo" color="#c80800" />
                  </TesouroInputFieldButton>
                </TesouroInputField>
              );
            })}
            <TesouroInputFieldButton
              type="button"
              onClick={() => handleAddTesouroAsset()}
            >
              <FiPlus title="Adicionar ativo" style={{ color: '#7086f5' }} />
            </TesouroInputFieldButton>
            <Input name="weight" icon={FiPercent} placeholder="Peso do grupo" />
            <div className="submitSavingsAccount">
              <Button
                type="button"
                onClick={() => handleToggleModal('addTesouro')}
              >
                Cancelar
              </Button>
              <Button type="submit">Adicionar</Button>
            </div>
          </UnformForm>
        </Modal>
      )}
      {isViewTesouroModalOpen && (
        <Modal
          open={isViewTesouroModalOpen}
          showCloseIcon={false}
          onClose={() => handleToggleModal('viewTesouro')}
          center
          classNames={{
            modal: 'addAssetModal',
          }}
          container={containerRef.current as Element}
        >
          <ViewAssetFormData>
            <h2>Detalhes do ativo</h2>
            <div className="viewRow">
              <div className="viewLabel">Código do ativo:</div>
              <div className="viewData">{tesouro[viewAssetIndex].code}</div>
            </div>
            <div className="viewRow">
              <div className="viewLabel">Títulos:</div>
              <div className="viewData">{tesouro[viewAssetIndex].name}</div>
            </div>
            <div className="viewRow">
              <div className="viewLabel">Peso do ativo:</div>
              <div className="viewData">
                {/* eslint-disable-next-line */}
                  {(tesouro[viewAssetIndex].weight / 100).toFixed(2)}
              </div>
            </div>
            <div className="closeButton">
              <Button
                type="button"
                onClick={() => handleToggleModal('viewTesouro')}
              >
                Fechar
              </Button>
            </div>
          </ViewAssetFormData>
        </Modal>
      )}
      {isDeleteTesouroModalOpen && (
        <Modal
          open={isDeleteTesouroModalOpen}
          showCloseIcon={false}
          onClose={() => handleToggleModal('deleteTesouro')}
          center
          classNames={{
            modal: 'addAssetModal',
          }}
          container={containerRef.current as Element}
        >
          <ViewAssetFormData>
            <h2>Detalhes do ativo</h2>
            <div className="viewRow">
              <div className="viewLabel">Nome do grupo:</div>
              <div className="viewData">{tesouro[viewAssetIndex].code}</div>
            </div>
            <div className="viewRow">
              <div className="viewLabel">Títulos no grupo:</div>
              <div className="viewData">{tesouro[viewAssetIndex].name}</div>
            </div>
            <div className="viewRow">
              <div className="viewLabel">Peso do ativo:</div>
              <div className="viewData">
                {/* eslint-disable-next-line */}
                  {(tesouro[viewAssetIndex].weight / 100).toFixed(2)}
              </div>
            </div>
            <div className="submitSavingsAccount">
              <Button
                type="button"
                onClick={() => handleToggleModal('deleteTesouro')}
              >
                Fechar
              </Button>
              <Button
                type="button"
                onClick={() => {
                  handleToggleModal('deleteTesouro');
                  removeAssetByIndex('tesouro', viewAssetIndex);
                }}
              >
                Deletar
              </Button>
            </div>
          </ViewAssetFormData>
        </Modal>
      )}
      {isEditTesouroModalOpen && (
        <Modal
          open={isEditTesouroModalOpen}
          showCloseIcon={false}
          onClose={() => handleToggleModal('editTesouro')}
          center
          classNames={{
            modal: 'addAssetModal',
          }}
          container={containerRef.current as Element}
        >
          <UnformForm ref={addTesouroFormRef} onSubmit={handleSaveAsset}>
            <h2>Editar Tesouro</h2>
            <Input name="type" hidden value="TD" />
            <Input
              name="code"
              icon={FiList}
              placeholder="Nome do grupo"
              defaultValue={tesouro[viewAssetIndex].code}
            />
            {tesouroInputs.map((input, index) => {
              return (
                <TesouroInputField>
                  <Input
                    name="name"
                    icon={FiList}
                    placeholder="Nome do título"
                    onChange={(e) => handleInputChange(index, e)}
                    defaultValue={input}
                  />
                  <TesouroInputFieldButton
                    type="button"
                    onClick={() => handleRemoveTesouroAsset(index)}
                  >
                    <FiTrash2 title="Deletar ativo" color="#c80800" />
                  </TesouroInputFieldButton>
                </TesouroInputField>
              );
            })}
            <TesouroInputFieldButton
              type="button"
              onClick={() => handleAddTesouroAsset()}
            >
              <FiPlus title="Adicionar ativo" style={{ color: '#7086f5' }} />
            </TesouroInputFieldButton>
            <Input
              name="weight"
              icon={FiPercent}
              placeholder="Peso do ativo"
              defaultValue={(tesouro[viewAssetIndex].weight / 100).toFixed(2)}
            />
            <div className="submitSavingsAccount">
              <Button
                type="button"
                onClick={() => handleToggleModal('editTesouro')}
              >
                Cancelar
              </Button>
              <Button type="submit">Salvar</Button>
            </div>
          </UnformForm>
        </Modal>
      )}
      <PortfolioArea>
        <h2>Carteira de investimentos</h2>
        <Subtitle>Ações</Subtitle>
        <DataCardsContainer>
          {stocks.length > 0 && (
            <>
              {stocks.map((stock, index) => {
                return (
                  <CardContainer>
                    <DataCard>
                      <LabelsContainer>
                        <LabelText>Código:</LabelText>
                        <LabelText>Nome:</LabelText>
                        <LabelText>Peso:</LabelText>
                      </LabelsContainer>
                      <ValuesContainer>
                        <ValueText>{stock.code}</ValueText>
                        <ValueText>{stock.name}</ValueText>
                        <ValueText>
                          {/* eslint-disable-next-line */}
                            {(stock.weight / 100).toFixed(2)}
                        </ValueText>
                      </ValuesContainer>
                    </DataCard>
                    <ButtonsContainer>
                      <ButtonContainer
                        onClick={() => {
                          setViewAssetIndex(index);
                          handleToggleModal('editStock');
                        }}
                      >
                        <FiEdit title="Editar ativo" color="#C36500" />
                      </ButtonContainer>
                      <ButtonContainer
                        className="central-button"
                        onClick={() => {
                          setViewAssetIndex(index);
                          handleToggleModal('viewStock');
                        }}
                      >
                        <FiSearch title="Visualizar ativo" />
                      </ButtonContainer>
                      <ButtonContainer
                        onClick={() => {
                          setViewAssetIndex(index);
                          handleToggleModal('deleteStock');
                        }}
                      >
                        <FiTrash2 title="Deletar ativo" color="#c80800" />
                      </ButtonContainer>
                    </ButtonsContainer>
                  </CardContainer>
                );
              })}
            </>
          )}
          <AddCardButton
            type="button"
            onClick={() => handleToggleModal('addStock')}
          >
            <FiPlusCircle title="Adicionar ação" />
          </AddCardButton>
        </DataCardsContainer>
        <Subtitle>FIIs</Subtitle>
        <DataCardsContainer>
          {fiis.length > 0 && (
            <>
              {fiis.map((fii, index) => {
                return (
                  <CardContainer>
                    <DataCard>
                      <LabelsContainer>
                        <LabelText>Código:</LabelText>
                        <LabelText>Nome:</LabelText>
                        <LabelText>Peso:</LabelText>
                      </LabelsContainer>
                      <ValuesContainer>
                        <ValueText>{fii.code}</ValueText>
                        <ValueText>{fii.name}</ValueText>
                        <ValueText>
                          {/* eslint-disable-next-line */}
                            {(fii.weight / 100).toFixed(2)}%</ValueText>
                      </ValuesContainer>
                    </DataCard>
                    <ButtonsContainer>
                      <ButtonContainer
                        onClick={() => {
                          setViewAssetIndex(index);
                          handleToggleModal('editFii');
                        }}
                      >
                        <FiEdit title="Editar ativo" color="#C36500" />
                      </ButtonContainer>
                      <ButtonContainer
                        className="central-button"
                        onClick={() => {
                          setViewAssetIndex(index);
                          handleToggleModal('viewFii');
                        }}
                      >
                        <FiSearch title="Visualizar ativo" />
                      </ButtonContainer>
                      <ButtonContainer
                        onClick={() => {
                          setViewAssetIndex(index);
                          handleToggleModal('deleteFii');
                        }}
                      >
                        <FiTrash2 title="Deletar ativo" color="#c80800" />
                      </ButtonContainer>
                    </ButtonsContainer>
                  </CardContainer>
                );
              })}
            </>
          )}
          <AddCardButton
            type="button"
            onClick={() => handleToggleModal('addFii')}
          >
            <FiPlusCircle title="Adicionar fii" />
          </AddCardButton>
        </DataCardsContainer>
        <Subtitle>Tesouro Direto</Subtitle>
        <DataCardsContainer>
          {tesouro.length > 0 && (
            <>
              {tesouro.map((ativo, index) => {
                return (
                  <CardContainer>
                    <DataCard>
                      <LabelsContainer>
                        <LabelText>Código:</LabelText>
                        <LabelText>Nome:</LabelText>
                        <LabelText>Peso:</LabelText>
                      </LabelsContainer>
                      <ValuesContainer>
                        <ValueText>{ativo.code}</ValueText>
                        <ValueText>{ativo.name}</ValueText>
                        <ValueText>
                          {/* eslint-disable-next-line */}
                            {(ativo.weight / 100).toFixed(2)}
                        </ValueText>
                      </ValuesContainer>
                    </DataCard>
                    <ButtonsContainer>
                      <ButtonContainer
                        onClick={() => {
                          setViewAssetIndex(index);
                          handleToggleModal('editTesouro');
                        }}
                      >
                        <FiEdit title="Editar ativo" color="#C36500" />
                      </ButtonContainer>
                      <ButtonContainer
                        className="central-button"
                        onClick={() => {
                          setViewAssetIndex(index);
                          handleToggleModal('viewTesouro');
                        }}
                      >
                        <FiSearch title="Visualizar ativo" />
                      </ButtonContainer>
                      <ButtonContainer
                        onClick={() => {
                          setViewAssetIndex(index);
                          handleToggleModal('deleteTesouro');
                        }}
                      >
                        <FiTrash2 title="Deletar ativo" color="#c80800" />
                      </ButtonContainer>
                    </ButtonsContainer>
                  </CardContainer>
                );
              })}
            </>
          )}
          <AddCardButton
            type="button"
            onClick={() => handleToggleModal('addTesouro')}
          >
            <FiPlusCircle title="Adicionar tesouro direto" />
          </AddCardButton>
        </DataCardsContainer>
        <PortfolioFooterContainer>
          <Button onClick={handleSubmitWallet} type="button">
            Salvar carteira
          </Button>
        </PortfolioFooterContainer>
      </PortfolioArea>
    </Container>
  );
};

export default Portfolio;
