/* eslint-disable react/jsx-one-expression-per-line */
import React, { useRef, useCallback, useState, useEffect } from 'react';
import { FiCreditCard, FiDollarSign, FiList, FiHash } from 'react-icons/fi';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import {
  Container,
  FormArea,
  MessageArea,
  InstallmentsAmountContainer,
} from './styles';

import Toolbar from '../../components/Toolbar';
import Button from '../../components/Button';
import Select from '../../components/SelectComponent';
import Input from '../../components/Input';
import SwitchComponent from '../../components/SwitchComponent';
import CheckboxInput from '../../components/CheckBox';
import DayPicker from '../../components/DayPicker';
import LoadingAnimation from '../../components/Loading';

import getValidationErrors from '../../utils/getValidationErros';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';

interface AccountData {
  id: string;
  name: string;
  type: string;
  active: boolean;
  weight?: number;
  deleted_at?: string;
}

interface SavingsAccountData {
  id: string;
  name: string;
  deleted_at: string;
}

interface TransactionsFormData {
  date: Date;
  location: string;
  amount: number;
  description: string;
  opType: boolean;
  account: AccountData;
  installments: number;
  priorityExpense: string[];
}

interface AccountsSelectOptions {
  value: AccountData | SavingsAccountData;
  label: string;
}

interface CheckboxOption {
  id: string;
  value: string;
  label: string;
}

const Transactions: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [opType, setOpType] = useState(0);
  const [accounts, setAccounts] = useState<AccountData[]>([]);
  const [savingsAccounts, setSavingsAccounts] = useState<SavingsAccountData[]>(
    [],
  );
  const [accountsSelectOptions, setAccountsSelectOptions] = useState<
    AccountsSelectOptions[]
  >([]);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const handleCheckbox = useCallback(() => {
    setIsCheckboxChecked(!isCheckboxChecked);
  }, [isCheckboxChecked]);

  const formRef = useRef<FormHandles>(null);

  const { addToast } = useToast();

  const checkboxOptions: CheckboxOption[] = [
    { id: 'priority-expense', value: 'true', label: 'Gasto prioritário' },
  ];

  const setNewFilter = useCallback(() => {
    const switchOption = opType;

    setOpType(switchOption === 1 ? 0 : 1);
  }, [opType]);

  const handleSubmit = useCallback(
    async (data: TransactionsFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          account: Yup.string().required('Escolha uma conta'),
          location: Yup.string()
            .required('Insira um nome para a transação')
            .max(25, 'Máximo de 25 caracteres'),
          amount: Yup.number()
            .typeError('Valor deve ser um número')
            .required('Insira um valor numérico para a transação'),
          description: Yup.string()
            .required('Insira uma descrição para a transação')
            .max(150, 'Máximo de 150 caracteres'),
          installments: Yup.number().optional().typeError('Deve ser um número'),
        });

        await schema.validate(data, { abortEarly: false });

        const payload = {
          transaction_type: opType === 1 ? 'OUT' : 'IN',
          account_type: data.account.type,
          date: format(data.date, 'dd/MM/yyyy'),
          amount: Math.round(data.amount * 100),
          account_id: data.account.id,
          location: data.location,
          description: data.description,
          priority_expense: data.priorityExpense.length > 0,
        };

        if (payload.transaction_type === 'OUT') {
          Object.assign(payload, { installments: Number(data.installments) });
        }

        await api.post('transaction', payload);

        addToast({
          type: 'success',
          title: 'Registro criado',
          description: 'Transação cadastrada com sucesso!',
        });

        formRef.current?.clearField('location');
        formRef.current?.clearField('amount');
        formRef.current?.clearField('description');
        formRef.current?.clearField('opType');
        formRef.current?.clearField('account');
        formRef.current?.clearField('priorityExpense');
        setIsCheckboxChecked(false);
        setOpType(0);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        const errorMessage = err.response?.data.message;

        if (errorMessage === 'Account not found.') {
          addToast({
            type: 'error',
            title: 'Erro no cadastro',
            description: 'Conta não encontrada, tente novamente.',
          });

          return;
        }

        if (errorMessage === 'Account name and operation type do not match') {
          addToast({
            type: 'error',
            title: 'Erro no cadastro',
            description:
              'Conta e tipo de operação (entrada/saída) não correspondem.',
          });

          return;
        }

        if (errorMessage === 'Savings account not found.') {
          addToast({
            type: 'error',
            title: 'Erro no cadastro',
            description: 'Poupança não encontrada, tente novamente',
          });

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Houve um erro inesperado, tente novamente mais tarde.',
        });
      }
    },
    [addToast, opType],
  );

  useEffect(() => {
    // Fetch accounts select data
    api.get<AccountData[]>('account').then(async (response) => {
      const availableAccounts = response.data.filter((account) => {
        return !account.deleted_at;
      });
      setAccounts(availableAccounts);

      // Fetch savings accounts select data
      const savingsAccountResponse = await api.get<SavingsAccountData[]>(
        'savings-account',
      );

      const availableSavingsAccounts = savingsAccountResponse.data.filter(
        (account) => {
          return !account.deleted_at;
        },
      );
      setSavingsAccounts(availableSavingsAccounts);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    const selectAccountsOptions = accounts
      .filter((account) => {
        const op = opType === 1 ? 'OUT' : 'IN';
        return account.type === op;
      })
      .map((selectOption) => {
        return { value: selectOption, label: selectOption.name };
      });

    const selectSavingsAccountsOptions = savingsAccounts.map((selectOption) => {
      return { value: selectOption, label: selectOption.name };
    });

    setAccountsSelectOptions([
      ...selectAccountsOptions,
      ...selectSavingsAccountsOptions,
    ]);
  }, [opType, accounts, savingsAccounts]);

  return loading ? (
    <Container>
      <Toolbar />
      <LoadingAnimation />
    </Container>
  ) : (
    <Container>
      <Toolbar />
      {accountsSelectOptions.length > 0 ? (
        <FormArea>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <h2>Registro de Transações</h2>
            <SwitchComponent
              name="opType"
              options={['Entrada', 'Saída']}
              id="op-type"
              clickEvent={setNewFilter}
            />
            <DayPicker name="date" />
            <CheckboxInput
              infoTitle="Gastos prioritários são somados e mostrados por mês na tela de transações."
              name="priorityExpense"
              options={checkboxOptions}
              onChange={handleCheckbox}
            />
            <Select
              name="account"
              icon={FiCreditCard}
              placeholder="Conta"
              selectOptions={accountsSelectOptions}
            />
            {opType === 0 ? (
              <Input name="amount" icon={FiDollarSign} placeholder="Valor" />
            ) : (
              <InstallmentsAmountContainer>
                <Input name="amount" icon={FiDollarSign} placeholder="Valor" />
                <Input
                  name="installments"
                  icon={FiHash}
                  placeholder="Parcelas"
                  defaultValue={1}
                />
              </InstallmentsAmountContainer>
            )}
            <Input name="location" icon={FiList} placeholder="Local" />
            <Input name="description" icon={FiList} placeholder="Descrição" />
            <Button type="submit">Registrar Transação</Button>
          </Form>
        </FormArea>
      ) : (
        <MessageArea>
          <div>
            Você precisa ter contas de entrada e saída cadastradas para começar
            a registrar suas transações
          </div>
          <br />
          <div>
            {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
            <Link to="account-register">Clique aqui</Link> para cadastrar novas
            contas ou
            <Link to="city-register"> aqui</Link> para cadastrar uma cidade
          </div>
        </MessageArea>
      )}
    </Container>
  );
};

export default Transactions;
