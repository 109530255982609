import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;

  .addAssetModal {
    background: #17171e;
    max-width: 500px;
    width: 30%;
    padding: 40px 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-radius: 10px;

    form {
      max-width: 80%;
    }

    h2 {
      text-align: center;
      font-size: 32px;
      color: #7086f5;
      padding-bottom: 32px;
    }

    h3 {
      color: #eea302;
      padding-bottom: 32px;
    }

    input {
      width: 300px;
    }

    @media only screen and (max-width: 601px) {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
    }

    .submitSavingsAccount {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 100%;
      button {
        max-width: 48%;
      }
    }

    .closeButton {
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 100%;
      button {
        max-width: 48%;
      }
    }
  }
`;

const appearFromBottom = keyframes`
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const PortfolioArea = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  animation: ${appearFromBottom} 1s;

  width: 90%;
  // margin: 140px 5%;
  padding: 16px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  background: #17171e;
  border-radius: 10px;

  h2 {
    font-size: 32px;
    color: #7086f5;
    padding-bottom: 48px;
  }

  a {
    color: #eea302;
  }

  @media only screen and (max-width: 601px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }
`;

export const ChartTitle = styled.h3`
  margin-top: 36px;
  margin-bottom: 16px;
`;

export const ChartArea = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media only screen and (max-width: 601px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }
`;

export const ChartBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;

  @media only screen and (max-width: 601px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: normal;
    justify-content: space-evenly;
  }
`;
