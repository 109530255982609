import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;

  .addAssetModal {
    background: #17171e;
    max-width: 500px;
    width: 30%;
    padding: 40px 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-radius: 10px;

    form {
      max-width: 80%;
    }

    h2 {
      text-align: center;
      font-size: 32px;
      color: #7086f5;
      padding-bottom: 32px;
    }

    h3 {
      color: #eea302;
      padding-bottom: 32px;
    }

    input {
      width: 300px;
    }

    @media only screen and (max-width: 601px) {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
    }

    .submitSavingsAccount {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 100%;
      button {
        max-width: 48%;
      }
    }

    .closeButton {
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 100%;
      button {
        max-width: 48%;
      }
    }
  }
`;

const appearFromBottom = keyframes`
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const PortfolioArea = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  animation: ${appearFromBottom} 1s;

  width: 90%;
  margin: 140px 5%;
  padding: 16px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  background: #17171e;
  border-radius: 10px;

  h2 {
    font-size: 32px;
    color: #7086f5;
    padding-bottom: 48px;
  }

  a {
    color: #eea302;
  }

  @media only screen and (max-width: 601px) {
    width: 100%;
  }
`;

export const Subtitle = styled.div`
  display: flex;

  font-size: 24px;
  font-weight: 500;
  color: #7086f5;

  margin-bottom: 16px;
`;

export const DataCardsContainer = styled.div`
  @media only screen and (min-width: 601px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  @media only screen and (max-width: 601px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 31%;
  border-radius: 10px;
  background-color: #b8b8b8;
  margin: 1% 0;

  @media only screen and (max-width: 601px) {
    width: 100%;
    margin: 2% 0;
  }
`;

export const DataCard = styled.div`
  @media only screen and (min-width: 601px) {
    display: flex;
    width: 100%;

    color: #000;
  }

  @media only screen and (max-width: 601px) {
    display: flex;
    width: 95%;
    border-radius: 10px;
    background-color: #b8b8b8;

    justify-content: space-evenly;
    color: #000;
  }
`;

export const LabelsContainer = styled.div`
  height: 100%;
  width: 22%;

  display: flex;
  flex-direction: column;
  align-items: start;
`;

export const ValuesContainer = styled.div`
  height: 100%;
  width: 75%;

  display: flex;
  flex-direction: column;
  align-items: start;
`;

export const ValueText = styled.div`
  display: inline-block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: justify;
  margin: 5% 0;
`;

export const LabelText = styled.div`
  margin: 17% 0;
  padding-left: 15%;
  width: 100%;
  display: flex;
  font-weight: 500;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 32px;
  border-top: 2px solid black;
  color: #000;

  .central-button {
    border-left: 2px solid black;
    border-right: 2px solid black;
    border-radius: 0;
  }
`;

export const ButtonContainer = styled.button`
  width: 33%;
  height: 100%;
  background-color: #b8b8b8;
  border: 0;
  border-radius: 0 0 15% 15%;
`;

export const AddCardButton = styled.button`
  border: 2px solid #7086f5;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 31%;
  border-radius: 10px;
  margin: 1% 0;
  height: 205px;
  background: #17171e;
  color: #7086f5;
  font-size: 128px;

  @media only screen and (max-width: 601px) {
    width: 100%;
    margin: 2% 0;
  }
`;

export const TesouroInputField = styled.div`
  display: flex;
  align-items: center;
  width: 95%;
`;

export const TesouroInputFieldButton = styled.button`
  background: #17171e;
  border: 0;
  margin-bottom: 16px;
  margin-left: 16px;
`;

export const ViewAssetFormData = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  /* align-items: baseline; */

  .viewRow {
    margin-bottom: 24px;
    display: flex;
    flex-direction: row;

    .viewLabel {
      width: 24%;
      margin-right: 24px;
      font-weight: 500;
      font-size: 18px;
    }

    .viewData {
      flex: 1;
      border-bottom: 1px solid white;
      text-align: center;
      background: transparent;
      color: #b8b8b8;
      padding-bottom: 4px;
      font-size: 18px;
    }
  }
`;

export const PortfolioFooterContainer = styled.div`
  display: flex;
  justify-content: end;
`;
